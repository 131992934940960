import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//LOGIN
import LoginView from '@/views/LoginView.vue'
import LoginRoleView from '@/views/LoginRoleView.vue'

//DASHBOARD
import DashboardView from '@/views/DashboardView.vue'
import DashboardSubKomunitasView from '@/views/DashboardSubKomunitasView.vue'

//REFLECTION
import CreateReflectionView from '@/views/create/CreateReflectionView.vue'
import EditReflectionView from '@/views/edit/EditReflectionView.vue'
import ListReflectionView from '@/views/list/ListReflectionView.vue'
import ListTrashReflectionView from '@/views/trash/ListTrashReflectionView.vue'
import ListReflectionSubKomunitasView from '@/views/list/ListReflectionSubKomunitasView.vue'
import CreateReflectionSubKomunitasView from '@/views/create/CreateReflectionSubKomunitasView.vue'
import EditReflectionSubKomunitasView from '@/views/edit/EditReflectionSubKomunitasView.vue'
import ListTrashReflectionSubKomunitasView from '@/views/trash/ListTrashReflectionSubKomunitasView.vue'

//ARTICLE
import CreateArticleView from '@/views/create/CreateArticleView.vue'
import EditArticleView from '@/views/edit/EditArticleView.vue'
import ListArticleView from '@/views/list/ListArticleView.vue'
import ListTrashArticleView from '@/views/trash/ListTrashArticleView.vue'

//POKOK DOA
import CreatePokokDoaView from '@/views/create/CreatePokokDoaView.vue'
import EditPokokDoaView from '@/views/edit/EditPokokDoaView.vue'
import ListPokokDoaView from '@/views/list/ListPokokDoaView.vue'

//AGENSI - COMMUNITY
import ListCommunityView from '@/views/list/ListCommunityView.vue'
import CreateCommunityView from '@/views/create/CreateCommunityView.vue'
import EditCommunityView from '@/views/edit/EditCommunityView.vue'
import ListTrashCommunityView from '@/views/trash/ListTrashCommunityView.vue'
import ListPICCommunityView from '@/views/list/ListPICCommunityView.vue'
import ListPICAgensiView from '@/views/list/ListPICAgensiView.vue'

//NEW VIDEO
import ListPlaylistVideoView from '@/views/list/ListPlaylistVideoView.vue'
import ListTrashPlaylistVideoView from '@/views/trash/ListTrashPlaylistVideoView.vue'
import ListVideoView from '@/views/list/ListVideoView.vue'

//TOPIC
import ListTopicView from '@/views/list/ListTopicView.vue'

//COMMUNITY - SUB COMMUNITY
import ListSubCommunityView from '@/views/list/ListSubCommunityView.vue'
import CreateSubCommunityView from '@/views/create/CreateSubCommunityView.vue'
import EditSubCommunityView from '@/views/edit/EditSubCommunityView.vue'
import ListPICAndMemberSubCommunity from '@/views/list/ListPICAndMemberSubCommunityView.vue'

//AGENDA
import ListAgendaView from '@/views/list/ListAgendaView.vue'
import CreateAgendaView from '@/views/create/CreateAgendaView.vue'
import CreateDetailAgendaView from '@/views/create/CreateDetailAgendaView.vue'
import EditDetailAgendaView from '@/views/edit/EditDetailAgendaView.vue'
import EditAgendaView from '@/views/edit/EditAgendaView.vue'
import ListAgendaSubKomunitasView from '@/views/list/ListAgendaSubKomunitasView.vue'
import CreateAgendaSubKomunitasView from '@/views/create/CreateAgendaSubKomunitasView.vue'
import EditAgendaSubKomunitasView from '@/views/edit/EditAgendaSubKomunitasView.vue'
import CreateDetailAgendaSubKomunitasView from '@/views/create/CreateDetailAgendaSubKomunitasView.vue'
import EditDetailAgendaSubKomunitasView from '@/views/edit/EditDetailAgendaSubKomunitasView.vue'
import PreviewDetailAgendaSubKomunitasView from '@/views/edit/PreviewDetailAgendaSubKomunitasView.vue'
import ListCopyAgendaView from '@/views/list/ListCopyAgendaView.vue'

//PRODUK
import ListProdukView from '@/views/list/ListProdukView.vue'
import EditThumbnailProdukView from '@/views/edit/EditThumbnailProduk.vue'
import CreateProdukView from '@/views/create/CreateProductView.vue'
import EditProdukView from '@/views/edit/EditProdukView.vue'

//SLIDER
import ListSliderView from '@/views/list/ListSliderView.vue'

//BROADCAST
import CreateBroadcastSubKomunitasView from '@/views/create/CreateBroadcastSubKomunitasView.vue'

//UNDUHAN
import ListUnduhanView from '@/views/list/ListUnduhanView.vue'
import CreateUnduhanView from '@/views/create/CreateUnduhanView.vue'

//SEMINAR KURSUS
import ListSeminarKursusView from '@/views/list/ListSeminarKursusView.vue'
import CreateSeminarKursusView from '@/views/create/CreateSeminarKursusView.vue'
import EditSeminarKursusView from '@/views/edit/EditSeminarKursusView.vue'

//DONASI
import ListDonasiView from '@/views/list/ListDonasiView.vue'

//USERS
import ListUsersView from '@/views/list/ListUsersView.vue'

const routes: Array<RouteRecordRaw> = [
  //LOGIN
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/role',
    name: 'role',
    component: LoginRoleView
  },
  //DASHBOARD
  {
    path: '/dashboard/:idAgensi/:agensi',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/dashboard-sub-komunitas/:idSubKomunitas/:subKomunitas',
    name: 'dashboard-sub-komunitas',
    component: DashboardSubKomunitasView
  },
  //REFLECTION
  {
    path: '/create-reflection/:agensi',
    name: 'create-reflection',
    component: CreateReflectionView
  },
  {
    path: '/edit-reflection/:agensi/:idReflection',
    name: 'edit-reflection',
    component: EditReflectionView
  },
  {
    path: '/list-reflection/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-reflection',
    component: ListReflectionView
  },
  {
    path: '/list-reflection/:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-reflection-search',
    component: ListReflectionView
  },
  {
    path: '/list-trash-reflection/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-trash-reflection',
    component: ListTrashReflectionView
  },
  {
    path: '/list-trash-reflection/:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-trash-reflection-search',
    component: ListTrashReflectionView
  },
  {
    path: '/list-reflection-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber',
    name: 'list-reflection-sub-komunitas',
    component: ListReflectionSubKomunitasView
  },
  {
    path: '/list-reflection-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber/:search',
    name: 'list-reflection-search-sub-komunitas',
    component: ListReflectionSubKomunitasView
  },
  {
    path: '/list-trash-reflection-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber',
    name: 'list-trash-reflection-sub-komunitas',
    component: ListTrashReflectionSubKomunitasView
  },
  {
    path: '/list-trash-reflection-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber/:search',
    name: 'list-trash-reflection-search-sub-komunitas',
    component: ListTrashReflectionSubKomunitasView
  },
  {
    path: '/create-reflection-sub-komunitas/:subKomunitas',
    name: 'create-reflection-sub-komunitas',
    component: CreateReflectionSubKomunitasView
  },
  {
    path: '/edit-reflection-sub-komunitas/:subKomunitas/:idReflection',
    name: 'edit-reflection-sub-komunitas',
    component: EditReflectionSubKomunitasView
  },
  //ARTICLE
  {
    path: '/create-article/:agensi',
    name: 'create-article',
    component: CreateArticleView
  },
  {
    path: '/edit-article/:agensi/:idArticle',
    name: 'edit-article',
    component: EditArticleView
  },
  {
    path: '/list-article/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-article',
    component: ListArticleView
  },
  {
    path: '/list-article/:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-article-search',
    component: ListArticleView
  },
  {
    path: '/list-trash-article/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-trash-article',
    component: ListTrashArticleView
  },
  {
    path: '/list-trash-article/:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-trash-article-search',
    component: ListTrashArticleView
  },
  //POKOK DOA
  {
    path: '/create-pokok-doa',
    name: 'create-pokok-doa',
    component: CreatePokokDoaView
  },
  {
    path: '/edit-pokok-doa',
    name: 'edit-pokok-doa',
    component: EditPokokDoaView
  },
  {
    path: '/list-pokok-doa/:agensi/:pageNumber',
    name: 'list-pokok-doa',
    component: ListPokokDoaView
  },
  //AGENSI - COMMUNITY
  {
    path: '/list-community/:agensi/:isSearch/:pageNumber',
    name: 'list-community',
    component: ListCommunityView
  },
  {
    path: '/list-community/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-community-search',
    component: ListCommunityView
  },
  {
    path: '/list-community/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-trash-community-search',
    component: ListCommunityView
  },
  {
    path: '/list-trash-community/:agensi/:isSearch/:pageNumber',
    name: 'list-trash-community',
    component: ListTrashCommunityView
  },
  {
    path: '/list-trash-community/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-trash-community-search',
    component: ListTrashCommunityView
  },
  {
    path: '/create-community/:agensi',
    name: 'create-community',
    component: CreateCommunityView
  },
  {
    path: '/edit-community/:agensi/:idCommunity',
    name: 'edit-community',
    component: EditCommunityView
  },
  {
    path: '/list-pic-community/:agensi/:idCommunity/:isSearch/:pageNumber/:communityName',
    name: 'list-pic-community',
    component: ListPICCommunityView
  },
  {
    path: '/list-pic-community/:agensi/:idCommunity/:isSearch/:pageNumber/:search/:communityName',
    name: 'list-pic-community-search',
    component: ListPICCommunityView
  },
  {
    path: '/list-pic-agensi/:agensi/:idCommunity/:isSearch/:pageNumber',
    name: 'list-pic-agensi',
    component: ListPICAgensiView
  },
  {
    path: '/list-pic-agensi/:agensi/:idCommunity/:isSearch/:pageNumber/:search',
    name: 'list-pic-agensi-search',
    component: ListPICAgensiView
  },
  //NEW VIDEO
  {
    path: '/list-playlist/:agensi/:isSearch/:pageNumber',
    name: 'list-playlist',
    component: ListPlaylistVideoView
  },
  {
    path: '/list-playlist/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-playlist-search',
    component: ListPlaylistVideoView
  },
  {
    path: '/list-trash-playlist/:agensi/:isSearch/:pageNumber',
    name: 'list-trash-playlist',
    component: ListTrashPlaylistVideoView
  },
  {
    path: '/list-trash-playlist/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-trash-playlist-search',
    component: ListTrashPlaylistVideoView
  },
  {
    path: '/list-video/:agensi/:idPlaylist/:playlistName/:isSearch/:pageNumber',
    name: 'list-video',
    component: ListVideoView
  },
  {
    path: '/list-video/:agensi/:idPlaylist/:playlistName/:isSearch/:pageNumber/:search',
    name: 'list-video-search',
    component: ListVideoView
  },
  //TOPIC
  {
    path: '/list-topic/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-topic',
    component: ListTopicView
  },
  {
    path: '/list-topic:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-topic-search',
    component: ListTopicView
  },
  //COMMUNITY - SUB COMMUNITY
  {
    path: '/list-sub-community/:agensi/:isSearch/:pageNumber',
    name: 'list-sub-community',
    component: ListSubCommunityView
  },
  {
    path: '/list-sub-community/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-sub-community-search',
    component: ListSubCommunityView
  },
  {
    path: '/create-sub-community/:agensi',
    name: 'create-sub-community',
    component: CreateSubCommunityView
  },
  {
    path: '/edit-sub-community/:agensi/:idSubCommunity',
    name: 'edit-sub-community',
    component: EditSubCommunityView
  },
  {
    path: '/list-member-sub-community/:agensi/:idSubCommunity/:isSearch/:pageNumber/:subCommunityName',
    name: 'list-member-sub-community',
    component: ListPICAndMemberSubCommunity
  },
  {
    path: '/list-member-sub-community/:agensi/:idSubCommunity/:isSearch/:pageNumber/:search/:subCommunityName',
    name: 'list-member-sub-community-search',
    component: ListPICAndMemberSubCommunity
  },
  //AGENDA
  {
    path: '/list-agenda/:agensi/:isSearch/:language/:pageNumber',
    name: 'list-agenda',
    component: ListAgendaView
  },
  {
    path: '/list-agenda/:agensi/:isSearch/:language/:pageNumber/:search',
    name: 'list-agenda-search',
    component: ListAgendaView
  },
  {
    path: '/create-agenda/:agensi',
    name: 'create-agenda',
    component: CreateAgendaView
  },
  {
    path: '/edit-agenda/:agensi/:idAgenda',
    name: 'edit-agenda',
    component: EditAgendaView
  },
  {
    path: '/create-detail-agenda/:agensi/:idAgenda',
    name: 'create-detail-agenda',
    component: CreateDetailAgendaView
  },
  {
    path: '/edit-detail-agenda/:agensi/:idAgenda',
    name: 'edit-detail-agenda',
    component: EditDetailAgendaView
  },
  {
    path: '/list-agenda-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber',
    name: 'list-agenda-sub-komunitas',
    component: ListAgendaSubKomunitasView
  },
  {
    path: '/list-agenda-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber/:search',
    name: 'list-agenda-search-sub-komunitas',
    component: ListAgendaSubKomunitasView
  },
  {
    path: '/create-agenda-sub-komunitas/:subKomunitas',
    name: 'create-agenda-sub-komunitas',
    component: CreateAgendaSubKomunitasView
  },
  {
    path: '/edit-agenda-sub-komunitas/:subKomunitas/:idAgenda',
    name: 'edit-agenda-sub-komunitas',
    component: EditAgendaSubKomunitasView
  },
  {
    path: '/create-detail-agenda-sub-komunitas/:subKomunitas/:idAgenda',
    name: 'create-detail-agenda-sub-komunitas',
    component: CreateDetailAgendaSubKomunitasView
  },
  {
    path: '/edit-detail-agenda-sub-komunitas/:subKomunitas/:idAgenda',
    name: 'edit-detail-agenda-sub-komunitas',
    component: EditDetailAgendaSubKomunitasView
  },
  {
    path: '/preview-detail-agenda-sub-komunitas/:subKomunitas/:idAgenda',
    name: 'preview-detail-agenda-sub-komunitas',
    component: PreviewDetailAgendaSubKomunitasView
  },
  {
    path: '/list-copy-agenda-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber',
    name: 'list-copy-agenda-sub-komunitas',
    component: ListCopyAgendaView
  },
  {
    path: '/list-copy-agenda-search-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber/:search',
    name: 'list-copy-agenda-search-sub-komunitas',
    component: ListCopyAgendaView
  },
  //PRODUK
  {
    path: '/create-produk/:agensi',
    name: 'create-produk',
    component: CreateProdukView
  },
  {
    path: '/edit-produk/:agensi/:idProduk',
    name: 'edit-produk',
    component: EditProdukView
  },
  {
    path: '/list-produk/:agensi/:isSearch/:pageNumber',
    name: 'list-produk',
    component: ListProdukView
  },
  {
    path: '/list-produk/:agensi/:isSearch/:pageNumber/:search',
    name: 'list-produk-search',
    component: ListProdukView
  },
  {
    path: '/edit-thumbnail-produk/:agensi/:idProduk/:namaProduk',
    name: 'edit-thumbnail-produk',
    component: EditThumbnailProdukView
  },
  //SLIDER
  {
    path: '/list-slider/:agensi/:isSearch/:pageNumber',
    name: 'list-slider',
    component: ListSliderView
  },
  //BROADCAST
  {
    path: '/create-broadcast-sub-komunitas/:subKomunitas/:isSearch/:language/:pageNumber',
    name: 'create-broadcast-sub-komunitas',
    component: CreateBroadcastSubKomunitasView
  },
  //UNDUHAN
  {
    path: '/list-unduhan/:agensi/:pageNumber',
    name: 'list-unduhan',
    component: ListUnduhanView
  },
  {
    path: '/create-unduhan/:agensi',
    name: 'create-unduhan',
    component: CreateUnduhanView
  },
  //SEMINAR KURSUS
  {
    path: '/list-seminar-kursus/:agensi/:pageNumber',
    name: 'list-seminar-kursus',
    component: ListSeminarKursusView
  },
  {
    path: '/create-seminar-kursus/:agensi',
    name: 'create-seminar-kursus',
    component: CreateSeminarKursusView
  },
  {
    path: '/edit-seminar-kursus/:agensi/:idSeminar',
    name: 'edit-seminar-kursus',
    component: EditSeminarKursusView
  },
  //DONASI
  {
    path: '/list-donasi/:agensi/:pageNumber',
    name: 'list-donasi',
    component: ListDonasiView
  },
  //USERS
  {
    path: '/list-users/:idAgensi/:agensi/:pageNumber',
    name: 'list-users',
    component: ListUsersView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _from, next) => {
  const token = sessionStorage.getItem("token")
  if (to.name !== "login" && !token) {
    next({ name: "login" })
  }
  if (to.name === "login" && token) {
    next({ name: "dashboard" })
  } else {
    next()
  }
})

export default router
